import { CNFlag } from '@components/icons/language/CNFlag';
import { USFlag } from '@components/icons/language/USFlag';
import { SGDFlag } from '@components/icons/currency/SGDFlag';
import { MYRFlag } from '@components/icons/currency/MYRFlag';
import { USDFlag } from '@components/icons/currency/USDFlag';
import siteLogo from '@public/assets/images/logo.svg';

export const siteSettings = {
  name: 'MichaelTrio',
  description:
    "Michael Trio is Singapore's trusted jewellery shop. Explore our wide collection of proposal rings, wedding bands, diamonds, gemstones and more.",
  author: {
    name: 'Michaeltrio',
    websiteUrl: 'https://www.michaeltrio.com',
    address: '',
  },
  logo: {
    url: siteLogo,
    alt: 'MichaelTrio Logo',
    href: '/',
    width: 128,
    height: 30,
  },
  defaultLanguage: 'en',
  currencyCode: 'USD',
  site_header: {
    menu: [
      {
        id: 3,
        path: '/search',
        label: 'menu-dietary',
        subMenu: [
          {
            id: 1,
            path: '/search',
            label: 'menu-vegetarian',
          },
          {
            id: 2,
            path: '/search',
            label: 'menu-kakogenic',
          },
          {
            id: 3,
            path: '/search',
            label: 'menu-mediterranean',
          },
          {
            id: 4,
            path: '/search',
            label: 'menu-organic',
          },
        ],
      },
      {
        id: 4,
        path: '/search/',
        label: 'menu-search',
      },
      {
        id: 5,
        path: '/shops/',
        label: 'menu-shops',
      },
      {
        id: 6,
        path: '/',
        label: 'menu-pages',
        subMenu: [
          {
            id: 1,
            path: '/',
            label: 'menu-users',
            subMenu: [
              {
                id: 1,
                path: '/my-account/account-settings',
                label: 'menu-my-account',
              },
              {
                id: 2,
                path: '/signin',
                label: 'menu-sign-in',
              },
              {
                id: 3,
                path: '/signup',
                label: 'menu-sign-up',
              },
            ],
          },
          {
            id: 2,
            path: '/faq',
            label: 'menu-faq',
          },
          {
            id: 3,
            path: '/about-us',
            label: 'menu-about-us',
          },
          {
            id: 4,
            path: '/privacy',
            label: 'menu-privacy-policy',
          },
          {
            id: 5,
            path: '/terms',
            label: 'menu-terms-condition',
          },
          {
            id: 6,
            path: '/contact-us',
            label: 'menu-contact-us',
          },
          {
            id: 7,
            path: '/checkout',
            label: 'menu-checkout',
          },
          {
            id: 8,
            path: '/404',
            label: 'menu-404',
          },
        ],
      },
    ],
    languageMenu: [
      {
        id: 'zh',
        name: '中国人 - ZH',
        value: 'zh',
        icon: <CNFlag />,
      },
      {
        id: 'en',
        name: 'English - EN',
        value: 'en',
        icon: <USFlag />,
      },
    ],
    currencyMenu: [
      {
        id: 'sgd',
        storeCode: 'sg',
        name: 'SGD',
        value: 'sgd',
        icon: <SGDFlag />,
        exchangeRate: 1,
        taxRate: 0.09,
      },
      {
        id: 'myr',
        storeCode: 'my',
        name: 'MYR',
        value: 'myr',
        icon: <MYRFlag />,
        exchangeRate: 3.55,
        taxRate: 0,
      },
      {
        id: 'usd',
        storeCode: 'intl',
        name: 'USD',
        value: 'usd',
        icon: <USDFlag />,
        exchangeRate: 0.74,
        taxRate: 0,
      },
    ],
    pagesMenu: [
      {
        id: 1,
        path: '/search',
        label: 'menu-best-deals',
      },
      {
        id: 2,
        path: '/about-us',
        label: 'menu-about-us',
      },
      {
        id: 3,
        path: '/contact-us',
        label: 'menu-contact-us',
      },
      {
        id: 4,
        path: '/faq',
        label: 'menu-faq',
      },
    ],
  },
};
