import http from '@framework/utils/http';
import Countries from '@public/api/countries.json';
import { siteSettings } from '@settings/site-settings';

export const GetCountryByCode = async (code: string) => {
  const response = await http
    .get('/config/countries?code=' + code)
    .then((res) => res.data);
  return await response;
};

export const GetCountryName = (code: string) => {

    if (!code)
    return code;

    let country = Countries.find((country) => country.code2 === code);

    if (!country)
    return code

    return country?.name ?? code;
};

// temporary solution
export const GetCurrencyConfig = (currencyCode: string) => {   

  return siteSettings.site_header?.currencyMenu?.find(
    (value: any) => value.value?.toLowerCase() === currencyCode?.toLowerCase(),
  );
};

export const GetTaxLabel = (currencyCode: string) => {
  const currency = GetCurrencyConfig(currencyCode);
  const taxRate = currency.taxRate * 100;

  return `GST ${taxRate}%`;
};
