import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import { getToken } from './get-token';

//const instance = Axios.create();
//const axios = setupCache(instance)

const axiosInstance = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_REST_API_ENDPOINT,
  withCredentials: true,
  //timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const http = setupCache(axiosInstance)

// Change request data/error here
http.interceptors.request.use(
    (config) => {
      const token = getToken();
  
      // Add token to headers
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

/**
 * http post with callback
 * @param url
 * @param data
 * @param callback
 * @returns
 */
export const httPost = (
  url: string,
  data: any,
  callback: (res: any) => void,
) => {
  return http.post(url, data).then(callback);
};

export default http;
