const storeCodes = {
  sg: 0,
  my: 2,
  intl: 3,
};

const storeCodeCurrency = {
  sg: 'SGD',
  my: 'MYR',
  intl: 'USD',
};

/**
 * Get Store Code
 * @param storeId
 * @returns
 */
export const GetStoreCode = (storeId: number) => {
  const store = Object.keys(storeCodes).find(
    (key) => storeCodes[key] === storeId,
  );

  return store ?? 'sg';
};

export const GetStoreId = (lang: string) => {
  const store = storeCodes[lang as keyof typeof storeCodes];

  return store;
};

export const GetStoreCurrency = (lang: string) => {
  const currency = storeCodeCurrency[lang as keyof typeof storeCodeCurrency];

  return currency;
};

// Get store code from currency
export const GetStoreCodeFromCurrency = (currency: string) => {
  const store = Object.keys(storeCodeCurrency).find(
    (key) => storeCodeCurrency[key] === currency.toUpperCase(),
  );

  return store;
};
